import React from 'react'
import styled, { css } from 'styled-components'

import BasicResponsiveVideo from '@babelcoder/gatsby-theme-base/src/components/BasicResponsiveVideo'
import ToggableContent from '@babelcoder/gatsby-theme-base/src/components/ToggableContent'
import MDXRenderer from '@babelcoder/gatsby-theme-courses/src/components/mdx/Renderer'
import UnitsTableProvider from '@babelcoder/gatsby-theme-courses/src/components/mdx/UnitsTableProvider'
import LessonsTable from './LessonsTable'
import LessonTitle from './LessonTitle'

const Wrapper = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.layout.height.header}px)`};
`

const Content = styled.div`
  max-width: 992px;
  margin: 0 auto;
`

const VideoPlayer = styled(BasicResponsiveVideo)`
  border-radius: ${({ theme }) => theme.round.large};
  overflow: hidden;
`

const Details = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacers.normal} 0;
    border-top: 1px solid ${theme.colors.neutral.gray300};
  `}
`

function Overview(props) {
  const { title, promotedVideo, details } = props

  return (
    <UnitsTableProvider>
      <Wrapper>
        <LessonsTable course={props}></LessonsTable>
        <ToggableContent>
          <Content>
            <LessonTitle>{title}</LessonTitle>
            <VideoPlayer url={promotedVideo}></VideoPlayer>
            <Details>
              <MDXRenderer>{details}</MDXRenderer>
            </Details>
          </Content>
        </ToggableContent>
      </Wrapper>
    </UnitsTableProvider>
  )
}

export default Overview
