import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'
import { CSSTransition } from 'react-transition-group'

import { HeaderContext } from './header/HeaderProvider'

const Wrapper = styled.aside`
  ${({ theme, isLeftCollapseMenu }) => css`
    position: relative;
    transform: translateX(${isLeftCollapseMenu ? 0 : '300px'});
    margin: ${theme.spacers.normal};
    height: 100%;
    ${!isLeftCollapseMenu &&
    css`
      margin-right: calc(300px + ${theme.spacers.normal});
    `};
    transition: transform 0.25s ease-in-out;

    ${down('medium')} {
      margin: ${theme.spacers.normal};
      transform: none;
    }
  `}
`

const Shady = styled.div`
  ${down('medium')} {
    ${({ theme }) => css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      background-color: ${theme.colors.neutral.black};
      z-index: ${theme.zIndex.overlay};

      &.shady {
        &-enter {
          opacity: 0;
        }

        &-enter-active {
          opacity: 0.5;
          transition: opacity 250ms;
        }

        &-exit {
          opacity: 0.5;
          transform: translateX(0);
        }

        &-exit-active {
          opacity: 0;
          transition: opacity 250ms;
        }
      }
    `}
  }
`

function ToggableSidebar({ className, children }) {
  const { isLeftCollapseMenu } = useContext(HeaderContext)

  return (
    <Wrapper isLeftCollapseMenu={isLeftCollapseMenu} className={className}>
      <CSSTransition
        in={!isLeftCollapseMenu}
        timeout={300}
        classNames="shady"
        unmountOnExit
      >
        <Shady></Shady>
      </CSSTransition>
      {children}
    </Wrapper>
  )
}

export default ToggableSidebar
