import React from 'react'
import { graphql } from 'gatsby'

import Overview from '../components/courses/Overview'

export default function OverviewTemplate({ data: { course } }) {
  return <Overview {...course}></Overview>
}

OverviewTemplate.layout = {
  headerType: 'fixed',
  toggleable: true,
  hasFooter: false,
}

export const pageQuery = graphql`
  query Overview($slug: String!) {
    course: coursesYaml(slug: { eq: $slug }) {
      slug
      name
      title
      promotedVideo
      details
      units {
        title
        lessons {
          title
          type
          slug
        }
      }
    }
  }
`
