import styled, { css } from 'styled-components'

const LessonTitle = styled.h2`
  ${({ theme }) => css`
    position: relative;
    text-align: center;
    margin: ${theme.spacers.normal} 0;
    font-size: ${theme.fontSizes.large};

    &::before {
      position: absolute;
      content: '';
      left: 50%;
      transform: translate(-50%);
      bottom: 0;
      width: 30px;
      height: 5px;
      border-radius: ${theme.round.large};
      background-color: ${theme.colors.main.primary};
    }
  `}
`

export default LessonTitle
